<div class="flex flex-col {{isModal?'m-10':'mt-10'}}" *ngIf="service == ''">
  <div class="flex-auto flex justify-center mb-2">
    <h3 class="flex-none text-xl tracking-wider text-white font-semibold">Post Your Requirement</h3><br>

  </div>
  <!-- <div class="flex-auto flex justify-center mb-4">
    <p class="flex items-center text-xs tracking-wider text-white font-semibold leading-4">
      {{serviceDetails.category}}
      <i class="fa-solid fa-chevron-right mx-1"></i>
      {{serviceDetails.displayText}}
    </p>

  </div> -->

  <div class=" flex-auto flex justify-center {{isModal?'':'h-screen'}}">
    <div
      class="relative bg-white shadow-md {{isModal?'w-full h-[400px]':'w-4/5 lg:w-2/3 xl:w-5/12 h-4/6'}} overflow-auto   rounded-3xl">
      <div class="m-5">

        <div
          class="bg-gray-100 mx-auto my-3 max-w-2xl rounded ring-1 ring-gray-200 sm:mt-1 lg:mx-0 lg:flex lg:max-w-none">
          <div class="lg:m-3 block w-full">
            <div class="relative rounded-md shadow-sm">
              <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span class="text-gray-500 sm:text-sm"><i class="fa-solid fa-magnifying-glass"></i></span>
              </div>
              <input type="text" name="price" focuse="true" id="price" (keyup)="getItems($event)"
                class="block w-full rounded-md border-0 py-2 pl-8 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Search...">
            </div>
          </div>

        </div>
        <div *ngFor="let category of groupedServices; let i = index">
          <div>


            <legend class="text-sm text-left font-bold tracking-wide text-slate-700">{{ category.key }}</legend>
            <div class="mt-2 space-y-6 mb-2">
              <ng-container>
                <div class="grid grid-cols-1 md:grid-cols-2 items-center gap-3">
                  <div *ngFor="let option of category.value; let j = index"
                    class="bg-[#F5F5F5] hover:bg-[#e0e0e0] rounded p-2">
                    <div class="flex gap-x-3 items-center" (click)="selectService(option)">
                      <img src="{{baseUrl}}/file/retrieve/{{option.smallIcon}}"
                        class="h-12 w-12 border-gray-300 text-indigo-600 focus:ring-indigo-600 rounded-md cursor-pointer">
                      <label [for]="option"
                        class="cursor-pointer block w-full text-left text-sm leading-6 text-gray-900">{{ option.title
                        }}</label>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <p class="text-center mt-4 tracking-normal text-xs lg:tracking-wider md:tracking-wider">Your Service not listed?
          Click the button below to submit a new service request!</p>
        <div class="flex justify-center">
          <button (click)="appC.openaddserviceRequestModal();appC.closeServiceRequestModal()"
            class="flex auto mt-3 py-2 px-8 text-sm font-bold tracking-wide rounded-lg bg-primary hover:bg-blue-700 text-white text-center border border-transparent focus:outline-none focus:border-blue-700 focus:ring focus:ring-blue-300">
            Submit Service Request
          </button>

        </div>
      </div>

    </div>

  </div>

</div>
<div class="flex flex-col {{isModal?'m-10':'mt-10'}}" *ngIf="service != ''">
  <div class="flex-auto flex justify-center">
    <h3 class="flex-none text-xl tracking-wider text-white font-semibold">Looking for {{serviceDetails.title}}?
    </h3><br>

  </div>
  <div class="flex-auto flex justify-center mb-4">
    <p class="flex items-center text-xs tracking-wider text-white font-semibold leading-4">
      {{serviceDetails.category}}
      <i class="fa-solid fa-chevron-right mx-1"></i>
      {{serviceDetails.title}}
    </p>

  </div>

  <div class=" flex-auto flex justify-center {{isModal?'':'h-screen'}}">
    <div
      class="relative bg-white shadow-md {{isModal?'w-full h-[400px]':'w-4/5 lg:w-2/3 xl:w-5/12 h-4/6'}} overflow-auto rounded-3xl">
      <div class="m-5 h-4/5 overflow-auto" *ngIf="!isPosted">


        <div *ngFor="let question of questions; let i = index" [class]="(currentStep !== i)?'hidden':''">
          <div>


            <legend class="text-base font-bold tracking-wide text-slate-700">{{ question.title }} <span
                class="text-[red]">*</span></legend>
            <div class="mt-2 space-y-6">
              <ng-container [ngSwitch]="question.type">
                <ng-container *ngSwitchCase="'radio'">
                  <div class="grid grid-cols-1 md:grid-cols-2 items-center gap-3">
                    <div *ngFor="let option of question.options; let j = index" class="bg-[#eee] rounded p-2 h-full ">
                      <div class="flex items-center gap-x-3">
                        <input [id]="option" type="radio" (change)="selectAnswer(question,$event)"
                          class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 cursor-pointer"
                          [(ngModel)]="question.answer" [value]="option" [name]="'question' + i">
                        <label [for]="option" class="cursor-pointer block w-full text-sm leading-6 text-gray-900">{{
                          option }}</label>
                      </div>

                    </div>
                    <div *ngIf="question?.answer?.toLowerCase().includes('other')" class="bg-[#eee] p-2 rounded">
                      <input type="text" placeholder="Type Here..."
                        class="h-4 w-full border-gray-300 bg-[#F5F5F5] text-sm focus:outline-none text-gray-900 focus:ring-indigo-600 cursor-pointer"
                        [(ngModel)]="question.otherValue">
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="'text'">
                  <div class="">
                    <input type="text" placeholder="Enter answer" class="bg-[#eee] rounded p-2 w-full"
                      [(ngModel)]="question.answer" [name]="'question' + i" />
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
        <div *ngIf="currentStep == questions.length && !isProject && !isGoogleAuth">
          <legend class="text-base  font-bold tracking-wide text-slate-700">Fill this details to get free quotes from
            partners</legend>
          <div class="w-full mt-3">
            <div class="grid w-full">
              <div class="relative rounded-md  mb-3">
                <p class="text-sm text-left tracking-wide text-slate-700">Mobile</p>
                <input type="text" [disabled]="userLoggedIn" (keyup)="mobileCheck($event)"
                  (input)="onMobileInput($event)" maxlength="10" pattern="[0-9]{10}" [(ngModel)]="mobile" name="mobile"
                  focuse="true" id="mobile"
                  class="block w-full rounded border-0 py-2 pl-3 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="">
                <p class="text-slate-500 text-xs flex items-center mt-1">
                  <img src="assets/icons/shield.png" class="w-5 h-5" alt="Shield Icon">
                  <span class="ml-1">Your number is secure as per our <a href="/company/privacy-policy"
                      target="_blank">Privacy Policy</a></span>
                </p>

              </div>
              <div class="relative rounded-md  mb-3 " *ngIf="isSendOTP">
                <p class="text-sm text-left tracking-wide text-slate-700">Name</p>
                <input type="text" [disabled]="userLoggedIn" [(ngModel)]="name" name="name" focuse="true" id="name"
                  class="block w-full rounded border-0 py-2 pl-3 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="">
              </div>

              <div class="relative rounded-md  mb-3 " *ngIf="isSendOTP">
                <p class="text-sm text-left tracking-wide text-slate-700">Email</p>
                <input type="email" [disabled]="userLoggedIn" [(ngModel)]="email" name="name" focuse="true" id="name"
                  class="block w-full rounded border-0 py-2 pl-3 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="">
              </div>
              <div class="relative rounded-md mb-3 grid justify-center" *ngIf="isOTP">
                <legend class="text-sm text-center font-semibold tracking-wide text-slate-700 my-3 mb-0">Enter OTP
                </legend>
                <p class="text-slate-500 text-xs text-center mt-0">
                  <span class="ml-1">OTP sent on text sms</span>
                </p>
                <ng-otp-input (onInputChange)="otpEnterd($event)" [config]="{length:6,allowNumbersOnly:true}">
                </ng-otp-input>
              </div>
              <div aria-label="Toast" class="mt-6 mx-auto bg-red-100 border border-red-200 rounded-md relative"
                *ngIf="error">
                <div class="p-4 rounded-md">
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"
                        class="nu rw ayv">
                        <path fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
                          clip-rule="evenodd"></path>
                      </svg>
                    </div>
                    <div class="ms-3">
                      <h3 class="font-semibold text-sm leading-5 text-red-600 tracking-wide">There were some errors with
                        your submission</h3>
                      <p class="font-normal text-sm leading-5 tracking-wider text-red-600">{{errorMessage}}</p>
                    </div>
                  </div>
                </div>
                <div class="absolute inset-x top-0 right-0 px-2 cursor-pointer" (click)="clearError()"
                  *ngIf="errorCancelAble"><i class="fa-solid fa-xmark text-orange-600"></i></div>
              </div>
            </div>


          </div>
        </div>
        <div *ngIf="currentStep == questions.length && !isProject && isGoogleAuth">
          <legend class="text-base  font-bold tracking-wide text-slate-700">Fill this details to get free quotes from
            partners</legend>
          <div class="w-full mt-3">
            <div class="grid w-full">
              <div class="relative rounded-md  mb-3">
                <p class="text-sm text-left tracking-wide text-slate-700">Mobile</p>
                <input type="text" [disabled]="userLoggedIn" (keyup)="mobileCheckModal($event)"
                  (input)="onMobileInputModal($event)" maxlength="10" pattern="[0-9]{10}" [(ngModel)]="mobileNo"
                  name="mobile" focuse="true" id="mobile"
                  class="block w-full rounded border-0 py-2 pl-3 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="">
                <p class="text-slate-500 text-xs flex items-center mt-1">
                  <img src="assets/icons/shield.png" class="w-5 h-5" alt="Shield Icon">
                  <span class="ml-1">Your number is secure as per our <a href="/company/privacy-policy"
                      target="_blank">Privacy Policy</a></span>
                </p>

              </div>
              <div class="relative rounded-md mb-3 grid justify-center" *ngIf="isOTPModal">
                <legend class="text-sm text-center font-semibold tracking-wide text-slate-700 my-3 mb-0">Enter OTP
                </legend>
                <p class="text-slate-500 text-xs text-center mt-0">
                  <span class="ml-1">OTP sent on text sms</span>
                </p>
                <ng-otp-input (onInputChange)="otpEnterd($event)" [config]="{length:6,allowNumbersOnly:true}">
                </ng-otp-input>
              </div>
              <div aria-label="Toast" class="mt-6 mx-auto bg-red-100 border border-red-200 rounded-md relative"
                *ngIf="errorModal">
                <div class="p-4 rounded-md">
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"
                        class="nu rw ayv">
                        <path fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
                          clip-rule="evenodd"></path>
                      </svg>
                    </div>
                    <div class="ms-3">
                      <h3 class="font-semibold text-sm leading-5 text-red-600 tracking-wide">There were some errors with
                        your submission</h3>
                      <p class="font-normal text-sm leading-5 tracking-wider text-red-600">{{errorMessageModal}}</p>
                    </div>
                  </div>
                </div>
                <div class="absolute inset-x top-0 right-0 px-2 cursor-pointer" (click)="clearErrorModal()"
                  *ngIf="errorCancelAbleModal"><i class="fa-solid fa-xmark text-orange-600"></i></div>
              </div>
            </div>


          </div>
        </div>

        <div *ngIf="currentStep == questions.length && isProject">
          <legend class="text-sm text-left font-bold tracking-wide text-slate-700">Select Project</legend>
          <div class="mt-2 space-y-6 mb-2">
            <ng-container>
              <div class="grid grid-cols-1 md:grid-cols-2 items-center gap-3">
                @if(projects.length > 0){
                <div *ngFor="let option of projects; let i = index" class="bg-[#F5F5F5] border rounded"
                  [class]="(isNext && !projectId)? 'border-red-500':'border-[#aaa]'"
                  [ngClass]="{'py-1 px-2': (option.editProjects), 'p-2': (!option.editProjects) }">
                  <div class="flex items-center gap-x-3" *ngIf="!option.editProjects">
                    <input [id]="i" type="radio" [checked]="option.isDefault" (change)="getProjectName($event,false)"
                      class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 cursor-pointer"
                      [(ngModel)]="projectId" [value]="option._id" [name]="'question' + i">

                    <label [for]="i" class="cursor-pointer block w-full text-sm font-medium leading-6 text-gray-900">{{
                      option.title }}</label>
                    <i class="fa-regular fa-pen-to-square" (click)="editProject(option,i)"></i>
                  </div>
                  <div class="flex" *ngIf="option.editProjects">
                    <input type="text" id="input{{i}}"
                      class="rounded-none rounded-s-md  bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm p-2 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      [(ngModel)]="option.title" placeholder="Enter Project Name">
                    <span (click)="editProjectName(option)"
                      class="cursor-pointer inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 hover:bg-gray-300 border border-s-0 border-gray-300 rounded-e-lg dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                      <i class="fa-solid fa-check" style="margin: auto; color: #4972ef; font-size: larger;"></i>
                    </span>
                  </div>
                </div>
                }
                <div>
                  <div class="flex items-center gap-x-3" *ngIf="!Projects">
                    <label
                      class="cursor-pointer block  text-sm font-medium leading-6 text-gray-900 bg-[#F5F5F5] rounded p-2 w-full text-center"
                      style="border: 1px solid blue;" (click)="AddProject()"><i class="fa-solid fa-plus me-2"></i>Add
                      Project</label>
                  </div>

                  <div class="grid grid-row-2 items-center gap-x-3 bg-[#F5F5F5] border rounded-lg"
                    [ngClass]="{'py-1 px-2': (Projects), 'p-2': (!Projects) }" *ngIf="Projects"
                    style="border: 1px solid gray;">
                    <div class="flex" *ngIf="Projects">
                      <input type="text"
                        class="rounded-none rounded-s-md  bg-gray-50 border border-gray-300 text-gray-900 focus:ring-blue-500 focus:border-blue-500 block flex-1 min-w-0 w-full text-sm p-2 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        [(ngModel)]="newProjectName" placeholder="Enter Project Name">
                      <span (click)="createdNewProject()" *ngIf="!newProjectStatus"
                        class="cursor-pointer inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 hover:bg-gray-300 border border-s-0 border-gray-300 rounded-e-lg dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                        <i class="fa-solid fa-check" style="margin: auto; color: #4972ef; font-size: larger;"></i>
                      </span>
                      <span *ngIf="newProjectStatus"
                        class="cursor-pointer inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 hover:bg-gray-300 border border-s-0 border-gray-300 rounded-e-lg dark:bg-gray-600 dark:text-gray-400 dark:border-gray-600">
                        <div role="status">
                          <svg aria-hidden="true"
                            class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                            viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor" />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill" />
                          </svg>
                          <span class="sr-only">Loading...</span>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <p class="text-red-600 text-xs">You can also rename of projects from “All projects” tab in Menu bar</p>
          </div>
        </div>
      </div>

      <div class="m-5 h-4/5 overflow-auto" *ngIf="isPosted">
        <div class="text-center">
          <svg viewBox="0 0 50 50" style="width:100px !important;height:100px !important;margin:0 auto;"
            xmlns="http://www.w3.org/2000/svg">
            <path class="checkmark" fill="none" stroke="#73AF55" stroke-width="4" d="M5 25 L20 40 L45 10" />
          </svg>
          <span class="text-large font-semibold text-[#73AF55]">Your service will post.</span>
          <br>
          <span>Please wait till admin approves and then Foot2Feet Partner will quote for
            {{serviceDetails.title}}</span>

        </div>
      </div>

      <div class="absolute inset-x-0 bottom-0 bg-white" *ngIf="!isPosted">
        <div class="flex gap-x-2 mx-5 my-2">
          <button [disabled]="isLoading" (click)="backStep()"
            *ngIf="currentStep != 0 && currentStep != questions.length"
            class="flex-none  w-1/6 p-1.5 rounded border-slate-400 bg-slate-200 hover:bg-slate-300 text-slate-700 text-center border"><i
              class="fa-solid fa-chevron-left"></i></button>
          <ng-container *ngIf="projectId != '' && projectId != undefined && projectId != null">
            <button
              [disabled]="isLoading || (currentStep < questions.length?(questions[currentStep]?.answer === undefined && currentStep != (questions.length)):'')"
              (click)="currentStep < questions.length ? nextStep() : (!isOTP && !isOTPModal && !userLoggedIn && !isProject && currentStep == questions.length) ? sendOTP() : onSubmit()"
              [class]="(currentStep==0) ? 'w-full':'w-full'"
              class="flex-initial p-1.5 disabled:bg-blue-200 disabled:cursor-not-allowed disabled:border-blue-200 rounded border-primary bg-primary hover:bg-blue-500 text-white text-center border">
              <ng-container *ngIf="currentStep < questions.length">Next</ng-container>
              <ng-container
                *ngIf="!isOTP && !isOTPModal && !userLoggedIn && !isProject && currentStep == questions.length">Send OTP
              </ng-container>
              <ng-container
                *ngIf="currentStep == questions.length && ((userLoggedIn || isOTP || isOTPModal))">Submit</ng-container>
            </button>
          </ng-container>
          <ng-container *ngIf="projectId == '' || projectId == undefined || projectId == null">
            <button
              [disabled]="isLoading || (currentStep < questions.length?(questions[currentStep]?.answer === undefined && currentStep != (questions.length)):'')"
              (click)="currentStep < questions.length ? nextStep() : (!isOTP && !isOTPModal && !userLoggedIn && !isProject && currentStep == questions.length) ? sendOTP() : ((isOTP || isOTPModal || userLoggedIn) && !isProject && currentStep == questions.length) ? nextSlide() :onSubmit()"
              [class]="(currentStep==0) ? 'w-full':'w-full'"
              class="flex-initial p-1.5 disabled:bg-blue-200 disabled:cursor-not-allowed disabled:border-blue-200 rounded border-primary bg-primary hover:bg-blue-500 text-white text-center border">
              <ng-container *ngIf="currentStep < questions.length">Next</ng-container>
              <ng-container
                *ngIf="!isOTP && !isOTPModal && !userLoggedIn && !isProject && currentStep == questions.length">Send OTP
              </ng-container>
              <ng-container
                *ngIf="currentStep == questions.length && ((userLoggedIn || isOTP || isOTPModal) && isProject)">Submit
              </ng-container>
              <ng-container
                *ngIf="currentStep == questions.length && ((userLoggedIn || isOTP || isOTPModal) && !isProject)">Next
              </ng-container>
            </button>
          </ng-container>
        </div>
        <h3 class="text-center my-3" *ngIf="!userLoggedIn && currentStep == questions.length && !isGoogleAuth">OR</h3>
        <asl-google-signin-button type="standard"
          *ngIf="!userLoggedIn && currentStep == questions.length && !isGoogleAuth" text="signin_with"
          logo_alignment="center" size='medium'></asl-google-signin-button>
      </div>
      <div class="absolute inset-x-0 bottom-0 bg-white" *ngIf="isPosted">
        <div class="flex gap-x-2 mx-5 my-2">
          <button (click)="navigate();appC.closeServiceRequestModal()"
            class="flex-initial p-1.5 disabled:bg-blue-200 disabled:cursor-not-allowed disabled:border-blue-200 rounded border-primary bg-primary hover:bg-blue-500 text-white text-center border w-full">
            Visit {{projectName}}
          </button>

        </div>
      </div>

    </div>

  </div>

</div>