import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Services } from "../../core/models/services";
import { animate, style, transition, trigger } from "@angular/animations";
import { AuthService } from "../../core/services/auth.service";
import { ApiService } from "../../core/services/api.service";
import Swal from 'sweetalert2';
import { Meta, Title } from '@angular/platform-browser';
import { SeoService } from '../../core/services/seo.service';
import { AppComponent } from '../../app.component';
import { CommonModule, DOCUMENT, isPlatformBrowser } from '@angular/common';
import { NgOtpInputModule } from 'ng-otp-input';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import { DataService } from '../../core/services/data.service';
import { GoogleSigninButtonModule, SocialAuthService } from '@abacritt/angularx-social-login';
import { Modal, initModals } from 'flowbite';
@Component({
  selector: 'app-service-request',
  standalone: true,
  templateUrl: './service-request.component.html',
  styleUrls: ['./service-request.component.scss'],
  imports: [CommonModule, FormsModule, NgOtpInputModule, GoogleSigninButtonModule],
})
export class ServiceRequestComponent implements OnInit, OnChanges {
  @Input() service = "";
  @Input() projectId = "";
  @Input() isModal = false;
  @Input() vendorId = "";
  @Input() directSendProposal = false;

  @Output() closeModal = new EventEmitter<string>();

  userLoggedIn = false;
  editProjects = true;
  Projects = false;
  serviceDetails: any = [];
  questions: any = [];
  groupedServices: any = [];
  searchGroupedServices: any = [];
  projects: any = [];
  form!: FormGroup;
  totalQuestionCount = 0;
  stepForm = 0;
  currentStep = 0;
  newProjectName = "";
  defaultProject = true;

  name = "";
  email = "";
  mobile: any;

  orderId: any = "";
  isLoading = false;
  isPosted = false;
  isNext = false;
  otp = "";
  otpValue = "";
  isOTP = false;
  seconds = 59;
  isResend = false;
  isProject = false;

  projectName: any = "";
  isSendOTP = false;

  error = false;
  errorMessage: String = "";
  editedName = "";
  NewProject = "";
  newProjectStatus = false;
  currentProjectId: any;
  errorCancelAble = false;

  servicesData: any = [];

  baseUrl = environment.baseURL;

  targetmobileModal: any;
  mobileModal: any;

  mobileNo: any;

  isGoogleAuth: any = false;
  isGoogleAuthRegister: any = false;
  isGoogleAuthUserData: any = [];
  isGoogleAuthSendOTP = false;
  isOTPModal = false;
  isSendOTPModal = false;
  isLoadingModal = false;
  isResendModal = false;
  googleUser: any = {};

  errorModal = false;
  errorMessageModal: String = "";
  errorCancelAbleModal = false;
  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router, public auth: AuthService, private api: ApiService, public appC: AppComponent, private titleService: Title, private seoService: SeoService, private metaService: Meta,
    @Inject(DOCUMENT) public document: Document, public toastr: ToastrService, public dataService: DataService, public authService: SocialAuthService,
    @Inject(PLATFORM_ID) private platformId: Object,) {
    this.titleService.setTitle(this.seoService.getTitle("services"));
    this.metaService.addTags(this.seoService.getSEOMetaData("services"));
    if (this.auth.currentUserValue) {
      this.userLoggedIn = true;
      this.name = this.auth.currentUserValue.name;
      this.email = this.auth.currentUserValue.email;
      this.mobile = this.auth.currentUserValue.mobile;
    }

  }

  nextSlide() {
    if (this.userLoggedIn) {
      this.isProject = true;
      this.api.getProject({ user: this.auth.currentUserValue.id }, 1, 10000, "").subscribe((data: any) => {
        this.projects = data.data;
        this.projectId = (data.data.find((res: any) => { return res.isDefault }))?._id;
      })
    } else {
      // if(this.otp == this.otpValue){
      if (!this.isGoogleAuth) {
        this.api.authenticateUser({ name: this.name, mobile: this.mobile, email: this.email, otp: this.otpValue, orderId: this.orderId, isPlatform: "website" }).subscribe(res => {
          localStorage.setItem('currentUser', JSON.stringify(res.data));
          this.auth.currentUserSubject.next(res.data);
          this.appC._fetchData();
          this.userLoggedIn = true;
          this.isProject = true;
          this.api.getProject({ user: res.data.id }, 1, 10000, "").subscribe((data: any) => {
            this.projects = data.data;
            this.projectId = (data.data.find((res: any) => { return res.isDefault }))?._id;
          })
        }, error => {
          this.isLoading = false;
          this.showError(error.error.error.message, true);
        });
      } else {
        this.api.authenticateUser({ name: this.googleUser.name, mobile: this.mobile, email: this.googleUser.email, otp: this.otpValue, orderId: this.orderId, isPlatform: "website", isGoogleAuth: this.isGoogleAuthRegister, userData: this.isGoogleAuthUserData._id }).subscribe(res => {
          localStorage.setItem('currentUser', JSON.stringify(res.data));
          this.auth.currentUserSubject.next(res.data);
          this.appC._fetchData();
          this.userLoggedIn = true;
          this.isProject = true;
          this.api.getProject({ user: res.data.id }, 1, 10000, "").subscribe((data: any) => {
            this.projects = data.data;
            this.projectId = (data.data.find((res: any) => { return res.isDefault }))?._id;
          })
          this.postRequest();

        }, error => {
          this.isLoading = false;
          this.showError(error.error.error.message, true);
        });
      }

      // }else{
      //   this.isLoading = false;
      //   this.showError("Incorrect OTP",true);
      // }
    }
  }

  mobileCheckModal(event: any) {
    this.clearError();
    if (event.target.value.toString().length == 10) {
      this.api.getUser({ mobile: parseInt(event.target.value) }, 1, 1, "").subscribe(us => {

        if (us.data.length == 0) {
          this.isGoogleAuthSendOTP = true;
        }

        if (us.data.length > 0) {
          if (us.data[0].role != "user") {
            this.showErrorModal("You are a Foot2Feet Partner! Please use mobile application or registered as a new user!", true);
          }
          if (us.data[0].role == "user" && us.data[0]._id != this.isGoogleAuthUserData._id && this.isGoogleAuthRegister) {
            this.showErrorModal("Please use another mobile for registered as a new user!", true);
          }
          if (us.data[0].role == "user") {
            this.showErrorModal("Please use another mobile for registered as a new user!", true);
          }
        }
      });
    } else {
      this.isGoogleAuthSendOTP = false;
    }

  }

  sendOTPModal() {
    const data = JSON.stringify({
      "mobile": this.mobile,
      "role": "user",
      "isPlatform": "website",
      "isGoogleAuth": true
    });
    this.api.sendOTP(data).subscribe(res => {
      if (res.status) {
        this.orderId = res.data.orderId;
        // this.otp = res.data.otp;
        this.isOTPModal = true;
        this.isLoadingModal = false;
        this.countdown();
      } else {
        this.isLoadingModal = false;
        this.showError(res.message, true);
      }
    }, error => {
      this.isLoadingModal = false;

      this.showErrorModal(error.error.error.message, true);
    });
  }

  resendOTPModal() {
    const data = JSON.stringify({
      "orderId": this.orderId
    });
    this.api.resendOTP(data).subscribe(res => {
      if (res.status) {
        // this.orderId =res.data.orderId;
        // this.otp = res.data.otp;
        this.isOTPModal = true;
        this.isLoadingModal = false;
        this.countdown();
      } else {
        this.isLoadingModal = false;
        this.showError(res.message, true);
      }
    }, error => {
      this.isLoadingModal = false;

      this.showErrorModal(error.error.error.message, true);
    });
  }

  verifyOTPModal() {
    this.isLoadingModal = true;
    // if(this.otp == this.otpValue){
    this.api.authenticateUser({ name: this.googleUser.name, mobile: this.mobile, email: this.googleUser.email, otp: this.otpValue, orderId: this.orderId, isPlatform: "website", isGoogleAuth: this.isGoogleAuthRegister, userData: this.isGoogleAuthUserData._id }).subscribe(res => {

      this.isLoadingModal = false;
      localStorage.setItem('currentUser', JSON.stringify(res.data));
      this.auth.currentUserSubject.next(res.data);
      this.appC._fetchData();

      this.postRequest();

    }, error => {
      this.isLoadingModal = false;

      this.showErrorModal(error.error.error.message, true);
    });
    // }else{
    //   this.isLoading = false;

    //   this.showError("Invalid OTP! Please try again");
    // }
  }

  none() { }

  showErrorModal(message: String, cancelAble = false) {
    this.errorModal = true;
    this.errorMessageModal = message;
    this.errorCancelAbleModal = cancelAble;

    if (!cancelAble) {
      setTimeout(() => {
        this.clearError();
      }, 10000);
    }

  }

  clearErrorModal() {
    this.errorModal = false;
    this.errorMessageModal = "";
    this.errorCancelAbleModal = false;
  }

  onMobileInputModal(event: Event) {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value.replace(/\D/g, '').substr(0, 10);
    input.value = inputValue;
    this.mobile = inputValue;
  }

  async ngOnInit() {
    this.authService.authState.subscribe(async (user) => {
      this.isGoogleAuth = true;
      if (user) {
        this.googleUser = user;
        let userData = (await this.api.getUser({ email: user.email }, 1, 1, "").toPromise()).data;
        if (userData.length > 0) {
          if (userData[0].mobile) {
            let data: any = { id: userData[0]._id, name: userData[0].name, email: userData[0].email, mobile: userData[0].mobile, walletBalance: userData[0].walletBalance, token: user.idToken, photo: userData[0].photo, userCode: userData[0].userCode, newUser: userData[0].newUser, status: userData[0].status }
            localStorage.setItem('currentUser', JSON.stringify(data));
            this.auth.currentUserSubject.next(data);
            this.appC._fetchData();
            this.userLoggedIn = true;
            this.isProject = true;
            this.api.getProject({ user: userData[0]._id }, 1, 10000, "").subscribe((data: any) => {
              this.projects = data.data;
              this.projectId = (data.data.find((res: any) => { return res.isDefault }))?._id;
            })
            this.postRequest();
          } else {
            this.isGoogleAuthRegister = true;
            this.isGoogleAuthUserData = userData[0];
          }
        } else {
        }
      }
    });
    this.isPosted = false;
    if (this.service == '') {
      await this.dataService.getServicesByCategory()
      this.searchGroupedServices = this.dataService.servicesByCategory;
      // this.servicesData = this.dataService.services.data;
      // this.searchGroupedServices = this.groupServicesByCategory();
      // this.searchGroupedServices.sort((a:any, b:any) => a.key.localeCompare(b.key));
      this.initializeItems();
    } else {
      this.getService();
    }

    if (isPlatformBrowser(this.platformId)) {
      initModals();
      this.targetmobileModal = this.document.getElementById('mobileModal');
      this.mobileModal = new Modal(this.targetmobileModal, { closable: false });
    }
  }

  openmobileModal() {
    this.mobileModal.show();
  }

  closemobileModal() {
    this.mobileModal.hide();
    this.mobile = "";
    this.isGoogleAuthRegister = false;
    this.isGoogleAuthUserData = [];
    this.isGoogleAuthSendOTP = false;
    this.isOTPModal = false;
    this.isSendOTPModal = false;
    this.isLoadingModal = false;
    this.isResendModal = false;
  }

  async ngOnChanges() {
    if (this.service == '') {
      await this.dataService.getServicesByCategory()
      // this.servicesData = this.dataService.services.data;
      this.searchGroupedServices = this.dataService.servicesByCategory;
      // this.searchGroupedServices.sort((a:any, b:any) => a.key.localeCompare(b.key));
      this.initializeItems();
    } else {
      this.getService();
    }
  }

  selectService(option: any) {
    this.service = option.url;
    this.getService();
  }

  async getService() {
    this.questions = [];
    let s = await this.api.getService({ url: this.service }, 1, 100, "").toPromise();
    this.serviceDetails = s.data[0];
    this.questions = JSON.parse(JSON.stringify(s.data[0].questions));
    this.totalQuestionCount = this.questions.length;

  }

  getProjectName(event: any, def: any) {
    if (def) {
      this.projectName = "My project";
    } else {
      this.projectName = this.projects[Number(event.target.id)].title;
    }
  }

  initializeItems(): void {
    this.groupedServices = JSON.parse(JSON.stringify(this.searchGroupedServices));
    this.groupedServices.sort((a: any, b: any) => a.key.localeCompare(b.key));
  }

  getItems(event: any) {
    this.initializeItems();
    const val = event.target.value;
    if (!val) {
      return;
    }
    this.groupedServices = this.groupedServices.map((obj: any) => {
      obj.value = obj.value.filter((item: any) => {
        return item.title.toLowerCase().includes(val.toLowerCase());
      });
      return obj;
    }).filter((obj: any) => obj.value.length > 0);
  }

  // groupServicesByCategory(): any[] {
  //   const grouped: any = {};
  //   for (const service of this.servicesData) {
  //     const category = service.category;
  //     if (!grouped[category]) {
  //       grouped[category] = [];
  //     }
  //     grouped[category].push(service);
  //   }
  //   return Object.entries(grouped).map(([key, value]:any) => ({
  //     key,
  //     value: value.sort((a:any, b:any) => a.title.localeCompare(b.title))
  //   }));
  // }

  countdown() {
    this.seconds = 59;
    let interval = setInterval(() => {
      this.seconds--;

      if (this.seconds <= 0) {
        clearInterval(interval);
        this.isResend = true;
      }
    }, 1000);
  }

  onMobileInput(event: Event) {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value.replace(/\D/g, '').substr(0, 10);
    input.value = inputValue;
    this.mobile = inputValue;
  }

  mobileCheck(event: any) {
    this.isLoading = true;
    if (event.target.value.toString().length == 10) {
      this.api.getUser({ mobile: parseInt(event.target.value) }, 1, 1, "").subscribe(us => {

        if (us.data.length == 0) {
          this.isSendOTP = true;
          this.isLoading = false;
        }

        if (us.data.length > 0) {
          if (us.data[0].role != "user") {
            this.showError("This Mobile No is assosiated with Partner Profile. Please try another mobile no to access website.");
            this.isSendOTP = false;
            this.isLoading = false;
          } else {
            this.isSendOTP = false;
            this.isLoading = false;
          }
        }
      });
    } else {
      this.isSendOTP = false;
      this.isLoading = false;
    }

  }

  showError(message: String, cancelAble = false) {
    this.error = true;
    this.errorMessage = message;
    this.errorCancelAble = cancelAble;

    if (!cancelAble) {
      setTimeout(() => {
        this.clearError();
      }, 4000);
    }

  }

  clearError() {
    this.error = false;
    this.errorMessage = "";
    this.errorCancelAble = false;
  }


  sendOTP() {
    if (this.isGoogleAuth) {
      const data = JSON.stringify({
        "mobile": this.mobile,
        "role": "user",
        "isPlatform": "website",
        "isGoogleAuth": true
      });
      this.api.sendOTP(data).subscribe(res => {
        if (res.status) {
          this.orderId = res.data.orderId;
          // this.otp = res.data.otp;
          this.isOTPModal = true;
          this.isLoadingModal = false;
          this.countdown();
        } else {
          this.isLoadingModal = false;
          this.showError(res.message, true);
        }
      }, error => {
        this.isLoadingModal = false;

        this.showErrorModal(error.error.error.message, true);
      });
    } else {
      const data = JSON.stringify({
        "mobile": this.mobile,
        "role": "user",
        "name": this.name,
        "email": this.email,
        "isPlatform": "website"
      });
      this.isLoading = true;
      this.api.sendOTP(data).subscribe(res => {
        if (res.status) {
          this.orderId = res.data.orderId;
          // this.otp = res.data.otp;
          this.isOTP = true;
          this.isLoading = false;
          this.countdown();
        } else {
          this.isLoading = false;
          this.showError(res.message, true);
        }
      }, error => {
        this.isLoading = false;

        this.showError(error.error.error.message, true);
      });
    }
  }

  resendOTP() {
    const data = JSON.stringify({
      "mobile": this.mobile,
      "role": "user",
      "name": this.name,
      "email": this.email,
      "otp": this.otp
    });
    this.isLoading = true;
    this.api.sendOTP(data).subscribe(res => {


      this.otp = res.data.otp;
      this.isOTP = true;
      this.isLoading = false;
      this.countdown();
    }, error => {
      this.isLoading = false;

    });
  }

  selectAnswer(question: any, event: any) {
    if (event.target.checked) {
      question.answer = event.target.id;
      this.currentStep++;
    }
  }

  // TODO: Validation, Toast
  onSubmit() {
    if (this.userLoggedIn) {
      this.postRequest();
    } else {
      // if(this.otp == this.otpValue){
      if (!this.isGoogleAuth) {
        this.api.authenticateUser({ name: this.name, mobile: this.mobile, email: this.email, otp: this.otpValue, orderId: this.orderId, isPlatform: "website" }).subscribe(res => {
          localStorage.setItem('currentUser', JSON.stringify(res.data));
          this.auth.currentUserSubject.next(res.data);
          this.appC._fetchData();

          this.postRequest();
        }, error => {
          this.isLoading = false;
          this.showError(error.error.error.message, true)
        });
      } else {
        this.api.authenticateUser({ name: this.googleUser.name, mobile: this.mobile, email: this.googleUser.email, otp: this.otpValue, orderId: this.orderId, isPlatform: "website", isGoogleAuth: this.isGoogleAuthRegister, userData: this.isGoogleAuthUserData._id }).subscribe(res => {
          localStorage.setItem('currentUser', JSON.stringify(res.data));
          this.auth.currentUserSubject.next(res.data);
          this.appC._fetchData();

          this.postRequest();
        }, error => {
          this.isLoading = false;
          this.showError(error.error.error.message, true)
        });
      }

      // }else{
      //   this.isLoading = false;
      //   this.showError("Incorrect OTP",true);
      // }
    }
  }

  navigate() {
    this.closeModal.emit('close');
    this.isPosted = false;
    this.router.navigate(['/project', this.projectId])
  }

  postRequest() {
    this.isLoading = true;
    if (!this.projectId) {
      this.isNext = true;
      this.isLoading = false;
      return;
    }
    // if(this.projects.length == 0){
    //   const pdata = JSON.stringify({
    //     title: "My Project",
    //     isDefault: true,
    //     user: this.auth.currentUserValue.id
    //   })
    //   this.api.createProject(pdata).subscribe((presData:any) => { 
    //     if(presData.status){
    //       this.projectId = presData.data._id;
    //       const data = JSON.stringify({
    //         serviceId : this.serviceDetails.id,
    //         questions:this.questions,
    //         category:this.serviceDetails.category,
    //         serviceLocation:this.questions[0].answer,
    //         displayServiceText:this.serviceDetails.displayText,
    //         user:this.auth.currentUserValue.id,
    //         projectId:this.projectId
    //       });

    //       this.api.createServiceRequest(data).subscribe(res=>{
    //         if(this.directSendProposal){
    //           let data = JSON.stringify({
    //             serviceRequest: res.data._id,
    //             cost: "",
    //             costType: "",
    //             isNegotiable: false,
    //             timeline: "",
    //             partner: this.vendorId,
    //             directProposalSend: true,
    //           });

    //           this.api.createProposal(data).subscribe(res=>{
    //             this.isLoading = false;
    //             this.isPosted = true;
    //           });
    //         }else{
    //            this.isLoading = false;
    //            this.isPosted = true;
    //         }
    //       })
    //     }
    //   })
    // }else{
    const data = JSON.stringify({
      serviceId: this.serviceDetails._id,
      questions: this.questions,
      category: this.serviceDetails.category,
      serviceLocation: this.questions[0].answer,
      displayServiceText: this.serviceDetails.title,
      user: this.auth.currentUserValue.id,
      projectId: this.projectId
    });

    this.api.createServiceRequest(data).subscribe(res => {
      if (this.directSendProposal) {
        let data = JSON.stringify({
          serviceRequest: res.data._id,
          cost: "",
          costType: "",
          isNegotiable: false,
          timeline: "",
          partner: this.vendorId,
          directProposalSend: true
        });

        this.api.createProposal(data).subscribe(res => {
          this.isLoading = false;
          // this.closeModal.emit('close');
          this.isPosted = true;
        });
      } else {
        this.isLoading = false;
        this.isPosted = true;
        // this.closeModal.emit('close');
      }
    })
    // }

  }


  otpEnterd(event: any) {
    if (event.length == 6) {
      this.otpValue = event;
    }
  }

  nextStep() {
    if (this.currentStep < this.questions.length) {
      this.currentStep++;
    } else {
      // Handle form submission or completion
      // this.onSubmit();
    }


  }

  backStep() {
    this.currentStep--;
  }

  editProject(item: any, i: any) {
    item.editProjects = true;
    this.currentProjectId = item._id;
    setTimeout(() => {
      this.document.getElementById('input' + i)?.focus();
    }, 200);

  }
  editProjectName(item: any) {
    const data = JSON.stringify({
      title: item.title
    })
    this.api.updateProject(data, this.currentProjectId).subscribe((cdata: any) => {
      if (cdata.status) {
        item.editProjects = false;
      } else {
        this.toastr.error(cdata.message);
        this.newProjectStatus = false;
      }
    }, error => {
      this.toastr.error(error.message);
      this.newProjectStatus = false;
    })
  }

  AddProject() {
    this.Projects = true;
  }

  createdNewProject() {
    this.newProjectStatus = true;
    if (!this.newProjectName) {
      this.toastr.error("Enter a project name");
      this.newProjectStatus = false;
      return;
    }
    const data = JSON.stringify({
      title: this.newProjectName,
      user: this.auth.currentUserValue.id,
    })
    this.api.createProject(data).subscribe((cdata: any) => {
      if (cdata.status) {
        this.Projects = false;
        this.projects.push({
          "_id": cdata.data._id,
          "title": this.newProjectName,
          "isDefault": false,
          "user": this.auth.currentUserValue,
          "createdAt": new Date()
        });
        this.newProjectName = "";
        this.newProjectStatus = false;
      } else {
        this.toastr.error(cdata.message);
        this.newProjectStatus = false;
      }
    }, error => {
      this.toastr.error(error.message);
      this.newProjectStatus = false;
    })
  }
}
