<div class="flex min-h-full flex-col justify-center" [class]="isSharedSignIn ? 'pb-12':'py-12 px-6 lg:px-8'">
  <div class="sm:mx-auto sm:w-full sm:max-w-sm" *ngIf="!isSharedSignIn">
    <img class="mx-auto h-10 w-auto" src="assets/logo.png" alt="Your Company">
    <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-normal text-gray-700 ">Get started to your
      account!</h2>
  </div>

  <div aria-label="Toast"
    class="mt-6 sm:mx-auto sm:w-full sm:max-w-sm mx-auto bg-red-100 border border-red-200 rounded-md relative"
    *ngIf="error">
    <div class="p-4 rounded-md">
      <div class="flex">
        <div class="flex-shrink-0">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"
            class="nu rw ayv">
            <path fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
              clip-rule="evenodd"></path>
          </svg>
        </div>
        <div class="ms-3">
          <h3 class="font-semibold text-sm leading-5 text-red-600 tracking-wide">There were some errors with your
            submission</h3>
          <p class="font-normal text-sm leading-5 tracking-wider text-red-600">{{errorMessage}}</p>
        </div>
      </div>
    </div>
    <div class="absolute inset-x top-0 right-0 px-2 cursor-pointer" (click)="clearError()" *ngIf="errorCancelAble"><i
        class="fa-solid fa-xmark text-orange-600"></i></div>
  </div>
  <div class=" sm:mx-auto sm:w-full sm:max-w-sm" [class]="isSharedSignIn ? '' : 'mt-6'">
    <form class="space-y-6" action="#" method="POST">
      <div>
        <label for="mobile" class="block text-sm font-medium leading-6 text-gray-700">Mobile</label>
        <div class="mt-2">
          <input id="mobile" [autocomplete]="false" name="mobile" maxlength="10" [(ngModel)]="mobileNo"
            (keyup)="mobileCheck($event)" type="text" pattern="[0-9]{10}" autocomplete="number"
            (input)="onMobileInput($event)" required
            class="block w-full rounded-md border-0 py-2 px-3 tracking-widest text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
        </div>
        <p class="text-slate-500 text-xs flex items-center mt-1">
          <img src="assets/icons/shield.png" class="w-5 h-5" alt="Shield Icon">
          <span class="ml-1">Your number is secure as per our <a href="/company/privacy-policy" target="_blank">Privacy
              Policy</a></span>
        </p>
      </div>

      <div *ngIf="isSendOTP">
        <label for="name" class="block text-sm font-medium leading-6 text-gray-700">Name</label>
        <div class="mt-2">
          <input id="name" name="name" [(ngModel)]="name" type="text" required
            class="block w-full rounded-md border-0 py-1.5 px-3 tracking-widest text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
        </div>
      </div>

      <div *ngIf="isSendOTP">
        <label for="email" class="block text-sm font-medium leading-6 text-gray-700">Email</label>
        <div class="mt-2">
          <input id="email" name="email" [(ngModel)]="email" type="email" required
            class="block w-full rounded-md border-0 py-1.5 px-3 tracking-widest text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
        </div>
      </div>

      <div *ngIf="isOTP">
        <div class="">
          <p for="otp" class="block text-sm font-medium leading-6 text-gray-700 text-center">Enter OTP</p>
          <p class="text-slate-500 text-xs text-center mt-0">
            <span class="ml-1">OTP sent on text sms</span>
          </p>
        </div>
        <div class="mt-2 text-center">
          <ng-otp-input (onInputChange)="otpEnterd($event)" [config]="{length:6,allowNumbersOnly:true}"></ng-otp-input>
        </div>
      </div>

      <div>
        <button type="submit" *ngIf="!isOTP" (click)="sendOTP()"
          class="flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2
          bg-blue-600 text-white hover:text-slate-100 hover:bg-blue-500 active:bg-blue-800 active:text-blue-100 focus-visible:outline-blue-600">Send OTP</button>
        <button type="submit" *ngIf="isOTP" (click)="verifyOTP()"
          class="flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 
          bg-blue-600 text-white hover:text-slate-100 hover:bg-blue-500 active:bg-blue-800 active:text-blue-100 focus-visible:outline-blue-600">Submit</button>
        <h3 *ngIf="isOTP" (click)="isResend ? resendOTP() : none()" [class]="isResend ? 'text-primary': ''"
          class="mt-5 text-lg  text-center">Resend OTP <span *ngIf="!isResend">in {{seconds}}</span></h3>
      </div>
    </form>
    <h3 class="text-center my-3">OR</h3>
    <asl-google-signin-button type="standard" text="signin_with" logo_alignment="center"
      size='large'></asl-google-signin-button>

  </div>
</div>

<!-- partner modal -->
<div id="welcomeModal" tabindex="-1" aria-hidden="true"
  class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
  <div class="relative m-2 w-full max-w-xl max-h-full bg-white rounded-md">
    <!-- Modal content -->
    <div class="">
      <div
        class="w-full p-4 text-center bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
        <img src="assets/images/party_popper.jpg" style="width: 25%;margin: 0 auto;">
        <h5 class="mb-2 text-xl font-bold text-gray-900 dark:text-white tracking-wide">Welcome To Foot2Feet!</h5>
        <h5 class="mb-2 text-base font-normal text-gray-900 dark:text-white tracking-wide">Your Construction Solution!
          Find professionals for building plans, land clearance, and approvals in Pune. Get FSI info, cost estimates,
          and 7/12 records online. Let's build together!</h5>
        <div class="items-center mt-5 justify-center space-y-4 sm:flex sm:space-y-0 sm:space-x-4 rtl:space-x-reverse">
          <button type="button" [routerLink]="[returnUrl]" (click)="closewelcomeModal()"
            class="inline-flex w-full justify-center rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto">Let's
            Go</button>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- partner modal -->
<div id="mobileModal" tabindex="-1" aria-hidden="true"
  class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
  <div class="relative m-2 w-full max-w-xl max-h-full bg-white rounded-md">
    <!-- Modal content -->
    <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
      <!-- Modal body -->
      <div aria-label="Toast"
        class="mt-6 sm:mx-auto sm:w-full sm:max-w-sm mx-auto bg-red-100 border border-red-200 rounded-md relative"
        *ngIf="errorModal">
        <div class="p-4 rounded-md">
          <div class="flex">
            <div class="flex-shrink-0">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"
                class="nu rw ayv">
                <path fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
                  clip-rule="evenodd"></path>
              </svg>
            </div>
            <div class="ms-3">
              <h3 class="font-semibold text-sm leading-5 text-red-600 tracking-wide">There were some errors with your
                submission</h3>
              <p class="font-normal text-sm leading-5 tracking-wider text-red-600">{{errorMessageModal}}</p>
            </div>
          </div>
        </div>
        <div class="absolute inset-x top-0 right-0 px-2 cursor-pointer" (click)="clearErrorModal()"
          *ngIf="errorCancelAbleModal">
          <i class="fa-solid fa-xmark text-orange-600"></i>
        </div>
      </div>
      <form class="p-4 md:p-5">
        <div class="grid gap-4 mb-4 grid-cols-2">
          <div class="col-span-2">
            <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Mobile</label>
            <input type="text" [autocomplete]="false" name="mobile" maxlength="10" [(ngModel)]="mobile"
              (keyup)="mobileCheckModal($event)" type="text" pattern="[0-9]{10}" autocomplete="number"
              (input)="onMobileInputModal($event)" required
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              placeholder="Type Mobile Number" required="">
          </div>
        </div>
        <div *ngIf="isOTPModal">
          <div class="">
            <p for="otp" class="block text-sm font-medium leading-6 text-gray-700 text-center">Enter OTP</p>
            <p class="text-slate-500 text-xs text-center mt-0">
              <span class="ml-1">OTP sent on text sms</span>
            </p>
          </div>
          <div class="mt-2 text-center">
            <ng-otp-input (onInputChange)="otpEnterd($event)"
              [config]="{length:6,allowNumbersOnly:true}"></ng-otp-input>
          </div>
        </div>
        <h3 *ngIf="isOTPModal" (click)="isResendModal ? resendOTPModal() : none()"
          [class]="isResend ? 'text-primary': ''" class="mt-5 text-lg  text-center">Resend OTP <span
            *ngIf="!isResendModal">in {{seconds}}</span></h3>
        <div class="sm:flex sm:flex-row-reverse text-end">
          <button type="button" *ngIf="!isOTPModal"
            class="inline-flex justify-center rounded-md bg-primary px-3 py-2 me-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
            (click)="sendOTPModal()">Send OTP</button>
          <button type="button" *ngIf="isOTPModal"
            class="inline-flex justify-center rounded-md bg-primary px-3 py-2 me-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
            (click)="verifyOTPModal()">Submit</button>
          <button type="button"
            class="mt-3 inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
            (click)="closemobileModal();clearErrorModal()">Cancel</button>

        </div>

      </form>
    </div>
  </div>
</div>