import { Component, EventEmitter, Inject, Input, Output, PLATFORM_ID } from '@angular/core';
import { ApiService } from '../../core/services/api.service';
import { AuthService } from '../../core/services/auth.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { CommonModule, DOCUMENT, isPlatformBrowser } from '@angular/common';
import { DataService } from '../../core/services/data.service';
import { SeoService } from '../../core/services/seo.service';
import { AppComponent } from '../../app.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { FormsModule } from '@angular/forms';
import { Modal, initModals } from 'flowbite';
import { GoogleSigninButtonModule, SocialAuthService } from '@abacritt/angularx-social-login';
declare global {
  interface Window {
    google: any;
  }
}


@Component({
  selector: 'app-signin',
  standalone: true,
  imports: [CommonModule, SigninComponent, NgOtpInputModule, FormsModule, RouterModule, GoogleSigninButtonModule],
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent {

  @Input() isSharedSignIn = false;
  @Output() authCompleted = new EventEmitter<string>();
  @Output() loginWithGoogle: EventEmitter<any> = new EventEmitter<any>();
  error = false;
  errorMessage: String = "";
  errorCancelAble = false;

  errorModal = false;
  errorMessageModal: String = "";
  errorCancelAbleModal = false;

  orderId: any = "";

  mobileNo: any;
  name: any;
  email: any;
  otp = "";
  otpValue = "";
  isOTP = false;
  isSendOTP = false;
  isLoading = false;
  allInfo = false;
  returnUrl = "";

  isResend = false;
  seconds = 59;

  targetwelcomeModal: any;
  welcomeModal: any;

  targetmobileModal: any;
  mobileModal: any;

  mobile: any;

  isGoogleAuthRegister: any = false;
  isGoogleAuthUserData: any = [];
  isGoogleAuthSendOTP = false;
  isOTPModal = false;
  isSendOTPModal = false;
  isLoadingModal = false;
  isResendModal = false;
  googleUser: any = {};
  constructor(private api: ApiService, private authService: SocialAuthService, public dataS: DataService, public auth: AuthService, private router: Router, public route: ActivatedRoute, public appC: AppComponent, private titleService: Title, private seoService: SeoService, private metaService: Meta,
    @Inject(PLATFORM_ID) private platformId: Object, @Inject(DOCUMENT) public document: Document,) {
    this.titleService.setTitle(this.seoService.getTitle("sign-in"));
    this.metaService.addTags(this.seoService.getSEOMetaData("sign-in"));
  }

  ngOnInit() {
    this.authService.authState.subscribe(async (user) => {
      if (user) {
        this.googleUser = user;
        let userData = (await this.api.getUser({ email: user.email }, 1, 1, "").toPromise()).data;
        if (userData.length > 0) {
          if (userData[0].mobile) {
            let data: any = { id: userData[0]._id, name: userData[0].name, email: userData[0].email, mobile: userData[0].mobile, walletBalance: userData[0].walletBalance, token: user.idToken, photo: userData[0].photo, userCode: userData[0].userCode, newUser: userData[0].newUser, status: userData[0].status }
            localStorage.setItem('currentUser', JSON.stringify(data));
            this.auth.currentUserSubject.next(data);
            this.dataS.getData();
            this.authCompleted.emit();
            this.appC._fetchData();
            if (!this.isSharedSignIn) {
              if (!data.newUser) {
                this.router.navigate([this.returnUrl]);
              } else {
                this.openwelcomeModal();
              }
            }
          } else {
            this.isGoogleAuthRegister = true;
            this.isGoogleAuthUserData = userData[0];
            this.openmobileModal();
          }
        } else {
          this.openmobileModal();
          // const data = JSON.stringify({
          //   name: user.name,
          //   email: user.email,
          //   role: "user",
          //   status: "Active"
          // })
          // this.api.registerUser(data).subscribe((res: any) => {

          // })
        }
      }
    });
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    if (isPlatformBrowser(this.platformId)) {
      initModals();
      this.targetwelcomeModal = this.document.getElementById('welcomeModal');
      this.welcomeModal = new Modal(this.targetwelcomeModal, { closable: false });
      this.targetmobileModal = this.document.getElementById('mobileModal');
      this.mobileModal = new Modal(this.targetmobileModal, { closable: false });
    }
  }

  create() {

  }

  mobileCheck(event: any) {
    this.clearError();
    if (event.target.value.toString().length == 10) {
      this.api.getUser({ mobile: parseInt(event.target.value) }, 1, 1, "").subscribe(us => {

        if (us.data.length == 0) {
          this.isSendOTP = true;
        }

        if (us.data.length > 0) {
          if (us.data[0].role != "user") {
            this.showError("You are a Foot2Feet Partner! Please use mobile application or registered as a new user!");
          }
        }
      });
    } else {
      this.isSendOTP = false;
    }

  }

  mobileCheckModal(event: any) {
    this.clearError();
    if (event.target.value.toString().length == 10) {
      this.api.getUser({ mobile: parseInt(event.target.value) }, 1, 1, "").subscribe(us => {

        if (us.data.length == 0) {
          this.isGoogleAuthSendOTP = true;
        }

        if (us.data.length > 0) {
          if (us.data[0].role != "user") {
            this.showErrorModal("You are a Foot2Feet Partner! Please use mobile application or registered as a new user!", true);
          }
          if (us.data[0].role == "user" && us.data[0]._id != this.isGoogleAuthUserData._id && this.isGoogleAuthRegister) {
            this.showErrorModal("Please use another mobile for registered as a new user!", true);
          }
          if (us.data[0].role == "user") {
            this.showErrorModal("Please use another mobile for registered as a new user!", true);
          }
        }
      });
    } else {
      this.isGoogleAuthSendOTP = false;
    }

  }

  onMobileInputModal(event: Event) {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value.replace(/\D/g, '').substr(0, 10);
    input.value = inputValue;
    this.mobile = inputValue;
  }

  openwelcomeModal() {
    this.welcomeModal.show();
  }

  closewelcomeModal() {
    this.welcomeModal.hide();
  }

  openmobileModal() {
    this.mobileModal.show();
  }

  closemobileModal() {
    this.mobileModal.hide();
    this.mobile = "";
    this.isGoogleAuthRegister = false;
    this.isGoogleAuthUserData = [];
    this.isGoogleAuthSendOTP = false;
    this.isOTPModal = false;
    this.isSendOTPModal = false;
    this.isLoadingModal = false;
    this.isResendModal = false;
  }

  onMobileInput(event: Event) {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value.replace(/\D/g, '').substr(0, 10);
    input.value = inputValue;
    this.mobileNo = inputValue;
  }

  sendOTPModal() {
    const data = JSON.stringify({
      "mobile": this.mobile,
      "role": "user",
      "isPlatform": "website",
      "isGoogleAuth": true
    });
    this.api.sendOTP(data).subscribe(res => {
      if (res.status) {
        this.orderId = res.data.orderId;
        // this.otp = res.data.otp;
        this.isOTPModal = true;
        this.isLoadingModal = false;
        this.countdown();
      } else {
        this.isLoadingModal = false;
        this.showError(res.message, true);
      }
    }, error => {
      this.isLoadingModal = false;

      this.showErrorModal(error.error.error.message, true);
    });
  }

  resendOTPModal() {
    const data = JSON.stringify({
      "orderId": this.orderId
    });
    this.api.resendOTP(data).subscribe(res => {
      if (res.status) {
        // this.orderId =res.data.orderId;
        // this.otp = res.data.otp;
        this.isOTPModal = true;
        this.isLoadingModal = false;
        this.countdown();
      } else {
        this.isLoadingModal = false;
        this.showError(res.message, true);
      }
    }, error => {
      this.isLoadingModal = false;

      this.showErrorModal(error.error.error.message, true);
    });
  }

  verifyOTPModal() {
    this.isLoadingModal = true;
    // if(this.otp == this.otpValue){
    this.api.authenticateUser({ name: this.googleUser.name, mobile: this.mobile, email: this.googleUser.email, otp: this.otpValue, orderId: this.orderId, isPlatform: "website", isGoogleAuth: this.isGoogleAuthRegister, userData: this.isGoogleAuthUserData._id }).subscribe(res => {

      this.isLoadingModal = false;
      localStorage.setItem('currentUser', JSON.stringify(res.data));
      this.auth.currentUserSubject.next(res.data);
      this.dataS.getData();
      this.authCompleted.emit();
      this.appC._fetchData();
      this.closemobileModal();
      if (!this.isSharedSignIn) {
        if (!res.data.newUser) {
          this.router.navigate([this.returnUrl]);
        } else {
          this.openwelcomeModal();
        }
      }

    }, error => {
      this.isLoadingModal = false;

      this.showErrorModal(error.error.error.message, true);
    });
    // }else{
    //   this.isLoading = false;

    //   this.showError("Invalid OTP! Please try again");
    // }
  }

  sendOTP() {
    const data = JSON.stringify({
      "mobile": this.mobileNo,
      "role": "user",
      "name": this.name,
      "email": this.email,
      "isPlatform": "website"
    });
    this.api.sendOTP(data).subscribe(res => {
      if (res.status) {
        this.orderId = res.data.orderId;
        // this.otp = res.data.otp;
        this.isOTP = true;
        this.isLoading = false;
        this.countdown();
      } else {
        this.isLoading = false;
        this.showError(res.message, true);
      }
    }, error => {
      this.isLoading = false;

      this.showError(error.error.error.message, true);
    });
  }

  resendOTP() {
    const data = JSON.stringify({
      "orderId": this.orderId
    });
    this.api.resendOTP(data).subscribe(res => {
      if (res.status) {
        // this.orderId =res.data.orderId;
        // this.otp = res.data.otp;
        this.isOTP = true;
        this.isLoading = false;
        this.countdown();
      } else {
        this.isLoading = false;
        this.showError(res.message, true);
      }
    }, error => {
      this.isLoading = false;

      this.showError(error.error.error.message, true);
    });
  }

  verifyOTP() {
    this.isLoading = true;
    // if(this.otp == this.otpValue){
    this.api.authenticateUser({ name: this.name, mobile: this.mobileNo, email: this.email, otp: this.otpValue, orderId: this.orderId, isPlatform: "website" }).subscribe(res => {

      this.isLoading = false;
      localStorage.setItem('currentUser', JSON.stringify(res.data));
      this.auth.currentUserSubject.next(res.data);
      this.dataS.getData();
      this.authCompleted.emit();
      this.appC._fetchData();
      if (!this.isSharedSignIn) {
        if (!res.data.newUser) {
          this.router.navigate([this.returnUrl]);
        } else {
          this.openwelcomeModal();
        }
      }

    }, error => {
      this.isLoading = false;

      this.showError(error.error.error.message, true);
    });
    // }else{
    //   this.isLoading = false;

    //   this.showError("Invalid OTP! Please try again");
    // }
  }

  showErrorModal(message: String, cancelAble = false) {
    this.errorModal = true;
    this.errorMessageModal = message;
    this.errorCancelAbleModal = cancelAble;

    if (!cancelAble) {
      setTimeout(() => {
        this.clearError();
      }, 10000);
    }

  }

  clearErrorModal() {
    this.errorModal = false;
    this.errorMessageModal = "";
    this.errorCancelAbleModal = false;
  }

  showError(message: String, cancelAble = false) {
    this.error = true;
    this.errorMessage = message;
    this.errorCancelAble = cancelAble;

    if (!cancelAble) {
      setTimeout(() => {
        this.clearError();
      }, 10000);
    }

  }

  otpEnterd(event: any) {
    if (event.length == 6) {
      this.otpValue = event;
    }
  }

  clearError() {
    this.error = false;
    this.errorMessage = "";
    this.errorCancelAble = false;
  }

  countdown() {
    this.seconds = 59;
    let interval = setInterval(() => {
      this.seconds--;

      if (this.seconds <= 0) {
        clearInterval(interval);
        this.isResend = true;
        this.isResendModal = true;
      }
    }, 1000);
  }

  none() { }
}
